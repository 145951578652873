// @flow

export const selectTransaction = (tx)=> ({
    type: "SELECTED_TX",
    payload: {
        tx: tx
    },
});


