// @flow

const Transaction = (state, action)=> {
    switch (action.type) {
        case "SELECTED_TX":
            return {
                ...state,
                tx: action.payload.tx,
            };
        default:
            return null;
    }
};

export default Transaction;
