import firebase from "firebase/app"
import "firebase/auth"
import "firebase/storage"
import "firebase/firestore"

//DEVELOPMENT
//BURN THESE KEYS
const app =firebase.initializeApp({
  apiKey: "AIzaSyC-NO2VsQD-XhUyAqiK5GQSRfbHbCmyFes",
  authDomain: "escrowd-dev.firebaseapp.com",
  projectId: "escrowd-dev",
  storageBucket: "escrowd-dev.appspot.com",
  messagingSenderId: "77966186536",
  appId: "1:77966186536:web:9e41b27f3cd7f1583c7d1d",
  measurementId: "G-88XV8S6DZY"
});

//PRODUCTION
/*
const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
})
*/


export const auth = app.auth()
export default app
