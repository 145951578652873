import React, {useState} from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import * as layoutConstants from '../constants/layout';

// All layouts/containers
import DefaultLayout from '../layouts/Default';
import VerticalLayout from '../layouts/Vertical';
import DetachedLayout from '../layouts/Detached';
import HorizontalLayout from '../layouts/Horizontal/';

import { authProtectedFlattenRoutes, publicProtectedFlattenRoutes } from './index';

import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Torus from "@toruslabs/torus-embed";
import Fortmatic from "fortmatic";

import { AuthProvider } from "../contexts/AuthContext"
import { ToastProvider } from 'react-toast-notifications'
import {Web3Context} from "../contexts/web3-context"

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      infuraId: "3b1014869e8f45bfaf89bce0f78a0e7c" // required
    }
  },
  fortmatic: {
    package: Fortmatic, // required
    options: {
      key: "pk_live_EF64FB98BE56B051" // required
    }
  },
  torus: {
    package: Torus, // required
    options: {
      networkParams: {
        host: "https://localhost:8545", // optional
        chainId: 1337, // optional
        networkId: 1337 // optional
      },
      config: {
        buildEnv: "development" // optional
      }
    }
  }
};

const web3Modal = new Web3Modal({
  network: "mainnet", // optional
  cacheProvider: true, // optional
  providerOptions // required
});


const Routes = (props) => {

    const [web3, setWeb3] = useState();
    const [account, setAccount] = useState();

    const clickConnect = async () =>{

        const provider = await web3Modal.connect();
        const _web3 = new Web3(provider);
        let activeAccount = (await _web3.eth.getAccounts())[0];

        setAccount(activeAccount);
        setWeb3(_web3);

        console.log((await _web3.eth.getAccounts())[0])
    }


    const { layout, user, transactions } = useSelector((state) => ({
        layout: state.Layout,
        user: state.Auth.user,
        transactions: state.Transaction
    }));

    const getLayout = () => {
        let layoutCls = VerticalLayout;

        switch (layout.layoutType) {
            case layoutConstants.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;
            case layoutConstants.LAYOUT_DETACHED:
                layoutCls = DetachedLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };

    let Layout = getLayout();

    return (
        <BrowserRouter>
            <AuthProvider>
                <ToastProvider>
                    <Web3Context.Provider value={
                    {
                        web3:web3,
                        account:account,
                        connectWeb3: clickConnect
                    }
                    }>
                        <Switch>

                            <Route path={publicProtectedFlattenRoutes.map((r) => r['path'])}>
                                <DefaultLayout {...props} layout={layout}>
                                    <Switch>
                                        {publicProtectedFlattenRoutes.map((route, index) => {
                                            return (
                                                !route.children && (
                                                    <route.route
                                                        key={index}
                                                        path={route.path}
                                                        roles={route.roles}
                                                        exact={route.exact}
                                                        component={route.component}
                                                    />
                                                )
                                            );
                                        })}
                                    </Switch>
                                </DefaultLayout>
                            </Route>



                            <Route path={authProtectedFlattenRoutes.map((r) => r['path'])}>
                                <Layout {...props} layout={layout} user={user}>
                                    <Switch>
                                        {authProtectedFlattenRoutes.map((route, index) => {
                                            return (
                                                !route.children && (
                                                    <route.route
                                                        key={index}
                                                        path={route.path}
                                                        roles={route.roles}
                                                        exact={route.exact}
                                                        component={route.component}
                                                    />
                                                )
                                            );
                                        })}
                                    </Switch>
                                </Layout>
                            </Route>


                        </Switch>
                    </Web3Context.Provider>
                </ToastProvider>
            </AuthProvider>
        </BrowserRouter>

    );
};

export default Routes;
/*
        <Router>
        <AuthProvider>
          <ToastProvider>
            <Web3Context.Provider value={
              {
                web3:web3,
                account:account,
                connectWeb3: clickConnect
              }
            }>
              <Switch>
                <PrivateRoute path="/update-profile" component={UpdateProfile} />
                <Route path="/signup" component={Signup} />
                <Route path="/login" component={Login} />
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route exact path="/" component={Landing} />
                <PrivateRoute path="/dashboard" component={Dashboard} />
                <Route path="/pool" component={Pool} />
                <Route path="/faucet" component={Faucet} />
                <Route path="/investor" component={InvestorApp} />

                <Route path="/dash" component={AnalyticsDashboardPage} />

                <PrivateRoute path="/uploadDocs" component={Upload} />
              </Switch>
            </Web3Context.Provider>
          </ToastProvider>
        </AuthProvider>
      </Router>
      <BrowserRouter>
            <Switch>
                <Route path={publicProtectedFlattenRoutes.map((r) => r['path'])}>
                    <DefaultLayout {...props} layout={layout}>
                        <Switch>
                            {publicProtectedFlattenRoutes.map((route, index) => {
                                return (
                                    !route.children && (
                                        <route.route
                                            key={index}
                                            path={route.path}
                                            roles={route.roles}
                                            exact={route.exact}
                                            component={route.component}
                                        />
                                    )
                                );
                            })}
                        </Switch>
                    </DefaultLayout>
                </Route>

                <Route path={authProtectedFlattenRoutes.map((r) => r['path'])}>
                    <Layout {...props} layout={layout} user={user}>
                        <Switch>
                            {authProtectedFlattenRoutes.map((route, index) => {
                                return (
                                    !route.children && (
                                        <route.route
                                            key={index}
                                            path={route.path}
                                            roles={route.roles}
                                            exact={route.exact}
                                            component={route.component}
                                        />
                                    )
                                );
                            })}
                        </Switch>
                    </Layout>
                </Route>
            </Switch>
        </BrowserRouter>
      */
